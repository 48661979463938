<template>
  <DSidebarView title="Games" color="purple" :items="subNavigation">
    <template #title-append>
      <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-purple-100 text-purple-700">
        <svg class="-ml-0.5 mr-1.5 h-2 w-2 text-purple-500" fill="currentColor" viewBox="0 0 8 8">
          <circle cx="4" cy="4" r="3" />
        </svg>
        {{ pitboss.online }} players online
      </span>
    </template>
  </DSidebarView>
</template>

<script setup>
import { provide, onBeforeUnmount } from 'vue'
import { LockClosedIcon } from '@heroicons/vue/outline'
import { DiscordIcon, SnekIcon } from '@ui'

import Pitboss from '@/components/roof/games/Pitboss'
import DSidebarView from '@/components/ui/layout/DSidebarView.vue'


const pitboss = new Pitboss()
const subNavigation = [
  {
    name: 'Flappy Snek',
    description: 'Tap to jump and avoid the pipes, like it\'s 2013 again. Last player remaining wins.',
    to: { name: 'FlappySnek' },
    icon: SnekIcon,
  },
  // {
  //   name: 'Always a Bigger Snek',
  //   description: 'Locked. Eat other sneks to grow, and avoid being eaten. Last player remaining wins.',
  //   to: { name: 'Slither' },
  //   icon: LockClosedIcon,
  // },
  // {
  //   name: 'Snek Armageddon',
  //   description: 'Locked. Use whatever tools and weapons are available to wipe out the competition.',
  //   to: { name: 'Armageddon' },
  //   icon: LockClosedIcon,
  // },
  // {
  //   name: 'Don\'t Let Go',
  //   description: 'Locked. Hold down your mouse while completing challenges. Last to let go wins.',
  //   to: { name: 'HoldIt' },
  //   icon: LockClosedIcon,
  // },
]

onBeforeUnmount(() => {
  pitboss.disconnect()
})

provide('pitboss', pitboss)
</script>