import { io } from 'socket.io-client'
import parser from 'socket.io-msgpack-parser'
import store from '@/store'

class Pitboss {
  constructor() {
    this._ws = null
    this.online = 0
    this.connect()
  }

  get isConnected() {
    return this._ws && this._ws.readyState === 1
  }

  connect() {
    if (this.isConnected) return
    const token = store.getters['auth/token']
    const host = process.env.NODE_ENV === 'production' ? 'https://dhh-pitboss-us.herokuapp.com' : 'http://localhost:3000'
    this._ws = io(`${host}?token=${token}`, { parser })
    
    // Listen for global stats
    this._ws.addEventListener('message', ([type, data]) => {
      if (type !== 'stats') return
      
      const [total, games] = data
      this.online = total
      games.forEach(([id, type, online]) => {
        if (type === 'FLAPPY') {
          store.dispatch('flappy/setStats', [id, [online]])
        }
      })
    })
  }

  disconnect() {
    if (this.isConnected) {
      this._ws.close()
    }
  }

  join(id) {
    this._ws.send(['join', id])
  }
}


export default Pitboss