<template>
  <div class="h-full flex flex-col">
    <!-- Page title & actions -->
    <div class="border-b border-gray-200 px-4 pt-5 pb-3 bg-white bg-opacity-75 backdrop-filter backdrop-blur z-10 sm:flex sm:items-center sm:justify-between sm:px-6 xl:pb-4 lg:px-8 lg:sticky lg:top-0">
      <div :class="`bg-gradient-to-r from-${color}-400 via-${color}-600 to-${color}-300 absolute top-0 left-0 w-full h-2`"></div>
      <div class="flex-1 min-w-0">
        <slot name="title">
          <nav class="flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li v-for="(item, i) in namedRoutes" :key="item.path">
                <div class="flex items-center">
                  <ChevronRightIcon v-if="i > 0" class="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                  <AppLink :to="item.path" :class="[i === namedRoutes.length - 1 ? 'text-gray-900' : 'text-gray-600', 'text-xl font-bold sm:text-2xl sm:truncate']">
                    {{ item.meta.displayName }}
                  </AppLink>
                </div>
              </li>
            </ol>
          </nav>
        </slot>
        <div v-if="items" class="mt-3 xl:hidden">
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select @change="redirect" id="tabs" name="tabs" :class="`block w-full focus:ring-${color}-500 focus:border-${color}-500 border-gray-300 rounded-md`">
              <option v-for="item in items" :key="item.name" :value="item.to.name" :selected="item.to.name === route.name">{{ item.name }}</option>
            </select>
          </div>
          <div class="hidden sm:block">
            <div>
              <nav class="flex space-x-4" aria-label="Tabs">
                <AppLink v-for="item in items" :key="item.name" :exact="item.exact" :to="item.to" class="px-3 py-2 font-medium text-sm rounded-md flex align-center" :active-class="`bg-${color}-100 bg-opacity-50 text-${color}-700`" inactive-class="text-gray-500 hover:text-gray-700" v-slot="{ active }">
                  <component :is="item.icon" :class="[active ? `text-${color}-500` : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                  {{ item.name }}
                </AppLink>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 flex sm:mt-0 sm:ml-4">
        <slot name="title-append"></slot>
      </div>
    </div>

    <div class="overflow-hidden flex-1 z-0 flex">
      <aside class="hidden relative xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
        <slot name="sidebar">
          <nav aria-label="Sections" class="hidden flex-grow-1 flex-shrink-0 w-96 bg-white border-r border-gray-200 xl:flex xl:flex-col">
            <div class="flex-1 min-h-0 overflow-y-auto">
              <AppLink v-for="item in items" :key="item.name" :to="item.to" :exact="item.exact" :active-class="`bg-${color}-50 bg-opacity-50`" :inactive-class="`hover:bg-${color}-50`" class="flex p-6 border-b border-gray-200">
                <slot name="item" :item="item">
                  <slot name="item-icon" :item="item">
                    <component :is="item.icon" class="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400" aria-hidden="true" />
                  </slot>
                  <div class="ml-3">
                    <slot name="item-contents" :item="item">
                      <p class="text-sm font-medium text-gray-900">{{ item.name }}</p>
                      <p class="mt-1 text-sm text-gray-500">{{ item.description }}</p>
                    </slot>
                  </div>
                </slot>
              </AppLink>
            </div>
          </nav>
        </slot>
        <slot name="sidebar-append"></slot>
      </aside>
      <main class="flex-1 flex relative z-0 overflow-y-auto focus:outline-none">
        <slot>
          <div :class="[active.contained !== false ? 'max-w-5xl' : '', 'flex-1 mx-auto']">
            <router-view />
          </div>
        </slot>
      </main>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { useBreakpoint } from '@ui'
import AppLink from '@/components/roof/AppLink.vue'

const props = defineProps({
  color: String,
  title: String,
  sticky: Boolean,
  items: Array,
})

const breakpoint = useBreakpoint()
const route = useRoute()
const router = useRouter()
const namedRoutes = computed(() => {
  if (breakpoint.smAndDown) {
    return route.matched.filter(({ meta }) => meta.displayName).slice(0, -1)
  }
  return route.matched.filter(({ meta }) => meta.displayName)
})
const active = computed(() => props.items.find(({ to }) => to.name === route.name))

const redirect = (e) => {
  router.push({ name: event.target.value })
}
</script>